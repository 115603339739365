<template>
  <v-dialog :value="value" persistent width="1200">
    <v-toolbar dark flat color="#1C3144">
      <v-btn icon @click="$emit('update:value', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>{{feature.featureType ? `${feature.adName} - ${feature.featureType.name}` : ''}}
      </v-toolbar-title>
    </v-toolbar>
    <v-container class="ma-0" fluid>
      <section class="pa-6">
        <v-row justify="center" dense>
          <v-radio-group v-model="type" row>
            <v-radio label="Feature" value="Feature"></v-radio>
            <v-radio label="Items" value="Items"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row dense justify="center">
          <v-col cols="5">
            <v-autocomplete auto-select-first outlined dense background-color="#fff" :items="ads" item-text="adName"
              item-value="adId" label="Select Destination Ad" v-model="destination.ad" @change="getDestinationAdFeatures"
              :menu-props="{ maxHeight:220 }" clearable/>
          </v-col>
          <v-col cols="5">
            <v-autocomplete auto-select-first outlined dense background-color="#fff" :items="features"
              item-value="featureType.id" item-text="featureType.name" label="Select Destination Feature Position"
              v-model="destination.feature" :menu-props="{ maxHeight:260 }" clearable>
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title>{{data.item.featureType.name}}</v-list-item-title>
                    <v-list-item-subtitle class="primary--text">{{data.item.brandCombo}}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </section>
      <v-divider />
      <v-data-table :expanded.sync="expanded" :headers="headers" :height="tableSize" :items-per-page="pageSize"
      :items.sync="items" v-model="selected" item-key="item.orderCode" class="table" dense fixed-header hide-default-footer
      :show-select="type === 'Items'">

        <template v-slot:expanded-item="{ headers, item }">
          <td></td>
          <td class="itemDetails" :colspan="headers.length">
            <strong>Cost:</strong>{{formatCurrency(item.item.cost)}}
            <v-divider class="mx-4" vertical />
            <strong>AMAP:</strong>{{formatCurrency(item.AMAP)}}
            <v-divider class="mx-4" vertical />
            <strong>EBA:</strong>{{formatCurrency(item.EBA)}}
            <v-divider class="mx-4" vertical />
            <strong>Ad BB:</strong>{{formatCurrency(item.adBB)}}
            <v-divider class="mx-4" vertical />
            <strong>Ad Scan:</strong>{{formatCurrency(item.adScan)}}
            <v-divider class="mx-4" vertical />
            <strong>ePay:</strong>{{formatCurrency(item.ePay)}}
            <v-divider class="mx-4" vertical />
            <strong>Compete:</strong>{{formatCurrency(item.compete)}}
            <v-divider class="mx-4" vertical />
            <strong>PCAF:</strong>{{formatCurrency(item.PCAF)}}
            <v-divider class="mx-4" vertical />
            <strong>Identifier:</strong> {{item.identifier}}
          </td>
        </template>

      </v-data-table>
    </v-container>
    <v-toolbar flat color="#fafafa" class="tableFooter">
      <v-spacer />
      <v-btn :disabled="!validDestination" color="orange" height="40" class="white--text"
        @click="type === 'Feature' ? copyFeature() : copyFeatureItems()">
        Copy
        <v-icon right>mdi-content-duplicate</v-icon>
      </v-btn>
      <v-btn :disabled="!validDestination" color="#0D68A5" height="40" class="white--text mx-2"
        @click="type === 'Feature' ? moveFeature() : moveFeatureItems()">
        Move
        <v-icon right>mdi-redo</v-icon>
      </v-btn>
    </v-toolbar>
  </v-dialog>
</template>

<script>
import { cloneDeep } from 'lodash'
import { tableSettings } from '@/mixins/table'
import { notification } from '@/mixins/notification'

export default {
  name: 'FeatureCopy',

  mixins: [tableSettings, notification],

  props: ['value', 'selectedAds', 'selectedFeature', 'featureTypes', 'getAdFeatures'],

  data () {
    return {
      ads: [],
      destination: {},
      dialog: false,
      expanded: [],
      features: [],
      items: [],
      pageSize: 1000,
      selected: [],
      type: 'Feature'
    }
  },

  computed: {
    feature () {
      return cloneDeep(this.selectedFeature)
    },

    validDestination () {
      return 'ad' in this.destination && 'feature' in this.destination
    },

    types () {
      return [
        'Copy',
        'Move'
      ]
    },

    headers () {
      return [
        { text: 'Order Code', align: 'center', sortable: true, filterable: true, value: 'item.orderCode', class: 'black--text' },
        { text: 'Pack', align: 'left', sortable: true, filterable: true, value: 'item.pack', class: 'black--text' },
        { text: 'Size', align: 'left', sortable: true, filterable: true, value: 'item.size', class: 'black--text' },
        { text: 'UPC', align: 'left', sortable: true, filterable: true, value: 'item.UPC', class: 'black--text' },
        { text: 'Item Description', align: 'left', sortable: true, filterable: true, value: 'item.description', class: 'black--text' }
      ]
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value) {
          this.getAds()
          this.getFeatureItems()
          this.selected = []
        } else {
          this.destination = {}
        }
      }
    }
  },

  created () {
    this.nonTableHeight = 460
  },

  methods: {
    formatCurrency (value) {
      return '$' + parseFloat(value).toFixed(2)
    },

    async getAds () {
      await this.$ads.getAds(0, 1000, '', '')
        .then(response => {
          this.ads = response
        }).catch(() => {
          this.notify('error', 'Error Retrieving Ads')
        })
    },

    async getFeatureItems () {
      await this.$ads.getFeatureItems(this.selectedFeature.featureId)
        .then(response => {
          this.items = response
          this.$data.expanded = this.$data.items
        })
    },

    async getDestinationAdFeatures (adId) {
      await this.$ads.getAdFeatures(adId)
        .then(response => {
          this.features = response
        })
    },

    async copyFeature () {
      this.showLoader('Copying Feature', true)

      await this.$ads.copyAdFeature(this.selectedFeature.featureId, this.destination.ad, this.destination.feature)
        .then(response => {
          if (!response.messages.length) {
            this.notify('success', 'Feature Successfully Copied')
          } else {
            try {
              this.showErrors(response.messages)
            } catch {
              this.notify('error', 'Feature Not Copied')
            }
          }
        }).catch(() => {
          this.notify('error', 'Feature Not Copied')
        })

      this.destination = {}
      this.selected = []

      this.getAdFeatures()
    },

    async copyFeatureItems () {
      this.showLoader('Copying Items', true)

      const promises = []
      const errors = []

      this.selected.forEach(item => {
        item.featureId = this.destination.feature

        delete item.orderCode
        delete item.adDetailId

        promises.push(
          this.$ads.saveFeatureItem(item)
            .then().catch(err => {
              try {
                errors.push(err.response.data.error)
              } catch {
                errors.push(`${item.item.orderCode} - ${item.item.description} Not Copied`)
              }
            })
        )
      })

      await Promise.allSettled(promises)

      if (errors.length) {
        this.showErrors(errors)
      } else {
        this.notify('success', 'Items Successfully Copied')
        this.destination = {}
        this.selected = []
      }
    },

    async moveFeature () {
      this.showLoader('Moving Feature', true)

      await this.$ads.moveAdFeature(this.selectedFeature.featureId, this.destination.ad, this.destination.feature)
        .then(response => {
          if (!response.messages) {
            this.notify('success', 'Feature Succesfully Moved')
            this.$emit('update:value', false)
          } else {
            try {
              this.notify('error', response.messages)
            } catch {
              this.notify('error', 'Feature Not Moved')
            }
          }
        }).catch(() => {
          this.notify('error', 'Feature Not Moved')
        })

      this.destination = {}
      this.selected = []

      this.getAdFeatures()
    },

    async moveFeatureItems () {
      this.showLoader('Moving Items', true)

      const promises = []
      const errors = []

      this.selected.forEach(item => {
        item.featureId = this.destination.feature

        delete item.orderCode

        promises.push(
          this.$ads.updateFeatureItem(item)
            .then().catch(err => {
              try {
                errors.push(err.response.data.error)
              } catch {
                errors.push(`${item.item.orderCode} - ${item.item.description} Not Copied`)
              }
            })
        )
      })

      await Promise.allSettled(promises)

      if (errors.length) {
        this.showErrors(errors)
      } else {
        this.notify('success', 'Items Successfully Moved')
        this.$emit('update:value', false)
        this.destination = {}
        this.selected = []
      }
    }
  }
}
</script>
<style scoped>
.table >>> tbody tr.v-data-table__expanded__content {
  background: #eeeeee;
  box-shadow: none;
}
</style>
