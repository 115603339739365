var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", width: "1200" } },
    [
      _c(
        "v-toolbar",
        { attrs: { dark: "", flat: "", color: "#1C3144" } },
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("update:value", false)
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
          _c("v-toolbar-title", [
            _vm._v(
              _vm._s(
                _vm.feature.featureType
                  ? _vm.feature.adName + " - " + _vm.feature.featureType.name
                  : ""
              ) + " "
            )
          ])
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "ma-0", attrs: { fluid: "" } },
        [
          _c(
            "section",
            { staticClass: "pa-6" },
            [
              _c(
                "v-row",
                { attrs: { justify: "center", dense: "" } },
                [
                  _c(
                    "v-radio-group",
                    {
                      attrs: { row: "" },
                      model: {
                        value: _vm.type,
                        callback: function($$v) {
                          _vm.type = $$v
                        },
                        expression: "type"
                      }
                    },
                    [
                      _c("v-radio", {
                        attrs: { label: "Feature", value: "Feature" }
                      }),
                      _c("v-radio", {
                        attrs: { label: "Items", value: "Items" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "", justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          "auto-select-first": "",
                          outlined: "",
                          dense: "",
                          "background-color": "#fff",
                          items: _vm.ads,
                          "item-text": "adName",
                          "item-value": "adId",
                          label: "Select Destination Ad",
                          "menu-props": { maxHeight: 220 },
                          clearable: ""
                        },
                        on: { change: _vm.getDestinationAdFeatures },
                        model: {
                          value: _vm.destination.ad,
                          callback: function($$v) {
                            _vm.$set(_vm.destination, "ad", $$v)
                          },
                          expression: "destination.ad"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          "auto-select-first": "",
                          outlined: "",
                          dense: "",
                          "background-color": "#fff",
                          items: _vm.features,
                          "item-value": "featureType.id",
                          "item-text": "featureType.name",
                          label: "Select Destination Feature Position",
                          "menu-props": { maxHeight: 260 },
                          clearable: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function(data) {
                              return [
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(data.item.featureType.name)
                                        )
                                      ]),
                                      _c(
                                        "v-list-item-subtitle",
                                        { staticClass: "primary--text" },
                                        [_vm._v(_vm._s(data.item.brandCombo))]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.destination.feature,
                          callback: function($$v) {
                            _vm.$set(_vm.destination, "feature", $$v)
                          },
                          expression: "destination.feature"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c("v-data-table", {
            staticClass: "table",
            attrs: {
              expanded: _vm.expanded,
              headers: _vm.headers,
              height: _vm.tableSize,
              "items-per-page": _vm.pageSize,
              items: _vm.items,
              "item-key": "item.orderCode",
              dense: "",
              "fixed-header": "",
              "hide-default-footer": "",
              "show-select": _vm.type === "Items"
            },
            on: {
              "update:expanded": function($event) {
                _vm.expanded = $event
              },
              "update:items": function($event) {
                _vm.items = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "expanded-item",
                fn: function(ref) {
                  var headers = ref.headers
                  var item = ref.item
                  return [
                    _c("td"),
                    _c(
                      "td",
                      {
                        staticClass: "itemDetails",
                        attrs: { colspan: headers.length }
                      },
                      [
                        _c("strong", [_vm._v("Cost:")]),
                        _vm._v(
                          _vm._s(_vm.formatCurrency(item.item.cost)) + " "
                        ),
                        _c("v-divider", {
                          staticClass: "mx-4",
                          attrs: { vertical: "" }
                        }),
                        _c("strong", [_vm._v("AMAP:")]),
                        _vm._v(_vm._s(_vm.formatCurrency(item.AMAP)) + " "),
                        _c("v-divider", {
                          staticClass: "mx-4",
                          attrs: { vertical: "" }
                        }),
                        _c("strong", [_vm._v("EBA:")]),
                        _vm._v(_vm._s(_vm.formatCurrency(item.EBA)) + " "),
                        _c("v-divider", {
                          staticClass: "mx-4",
                          attrs: { vertical: "" }
                        }),
                        _c("strong", [_vm._v("Ad BB:")]),
                        _vm._v(_vm._s(_vm.formatCurrency(item.adBB)) + " "),
                        _c("v-divider", {
                          staticClass: "mx-4",
                          attrs: { vertical: "" }
                        }),
                        _c("strong", [_vm._v("Ad Scan:")]),
                        _vm._v(_vm._s(_vm.formatCurrency(item.adScan)) + " "),
                        _c("v-divider", {
                          staticClass: "mx-4",
                          attrs: { vertical: "" }
                        }),
                        _c("strong", [_vm._v("ePay:")]),
                        _vm._v(_vm._s(_vm.formatCurrency(item.ePay)) + " "),
                        _c("v-divider", {
                          staticClass: "mx-4",
                          attrs: { vertical: "" }
                        }),
                        _c("strong", [_vm._v("Compete:")]),
                        _vm._v(_vm._s(_vm.formatCurrency(item.compete)) + " "),
                        _c("v-divider", {
                          staticClass: "mx-4",
                          attrs: { vertical: "" }
                        }),
                        _c("strong", [_vm._v("PCAF:")]),
                        _vm._v(_vm._s(_vm.formatCurrency(item.PCAF)) + " "),
                        _c("v-divider", {
                          staticClass: "mx-4",
                          attrs: { vertical: "" }
                        }),
                        _c("strong", [_vm._v("Identifier:")]),
                        _vm._v(" " + _vm._s(item.identifier) + " ")
                      ],
                      1
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          })
        ],
        1
      ),
      _c(
        "v-toolbar",
        { staticClass: "tableFooter", attrs: { flat: "", color: "#fafafa" } },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "white--text",
              attrs: {
                disabled: !_vm.validDestination,
                color: "orange",
                height: "40"
              },
              on: {
                click: function($event) {
                  _vm.type === "Feature"
                    ? _vm.copyFeature()
                    : _vm.copyFeatureItems()
                }
              }
            },
            [
              _vm._v(" Copy "),
              _c("v-icon", { attrs: { right: "" } }, [
                _vm._v("mdi-content-duplicate")
              ])
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "white--text mx-2",
              attrs: {
                disabled: !_vm.validDestination,
                color: "#0D68A5",
                height: "40"
              },
              on: {
                click: function($event) {
                  _vm.type === "Feature"
                    ? _vm.moveFeature()
                    : _vm.moveFeatureItems()
                }
              }
            },
            [
              _vm._v(" Move "),
              _c("v-icon", { attrs: { right: "" } }, [_vm._v("mdi-redo")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }